.body-wrap {
  overflow: hidden;
  border-radius: 8px;

  h3 {
    margin: 0;
    background: #383067;
    color: white;
    font-size: 20px;
    padding: 8px 16px;
  }

  .body {
    background: white;
    padding: 20px;
    font-size: 18px;
  }
}

//Gauge
// .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-formControl {
//   background: white;
// }

#length-output,
.length-output {
  margin-top: 14px;
}

.size-info-wrap {
  margin: 14px 0;
  strong {
    margin-bottom: 10px;
    display: inline-block;
  }
}
