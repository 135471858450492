.metal-wrapper {
  box-shadow: 1px 1px 19px #00000021;
  border-radius: 8px;
  overflow: hidden; /* force border-radius */
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
}

.metal-wrapper h2 {
  margin: 0;
  padding: 10px;
  text-align: center;
  background: #3819b5;
  background: #362e69;
  color: white;
  font-size: 24px;
}

.metal-wrapper h3 {
  text-transform: uppercase;
  font-size: 16px;
  margin: 0 0 10px;
  font-weight: 700;
}

.metal-wrapper .tag-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.metal-wrapper .content-wrap {
  padding: 20px;
  background: white;
}

/* .metal-wrapper span:not(.MuiSkeleton-root) {
  padding: 5px 8px;
  margin: 0 10px 10px 0;
  background: #efefef;
  border-radius: 4px;
} */

.tag-wrap a {
  font-weight: normal;
}

.tag-wrap a:hover {
  /* text-decoration: none; */
}

a.MuiButtonBase-root {
  flex: 1;
}

a.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.Mui-selected {
  background: #efefef !important;
  color: black;
  transition: 1s;
}

.MuiTabs-indicator {
  background-color: #3819b5 !important;
  background-color: #5b6f97 !important;
}

.MuiTabs-flexContainer {
  justify-content: center;
}

ul.footer-resources{
  list-style-type: none;
  margin-left: 20px;
}
ul.footer-resources a{
  color: white;
  text-decoration: underline;
}
ul.footer-resources a:hover{
  color: #ccc;
}
@media (max-width: 960px) {
  ul.footer-resources, .footer-resources-heading {
    margin-left: 0px !important;
    padding-left: 0px; 
  }
}
div.MuiBox-root p a{
  color: rgb(55, 47, 104);
  text-decoration: underline;
}
div.MuiBox-root p a:hover{
  color: #5b6f97;
}