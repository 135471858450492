.form-wrap {
  &.is-submitting {
    padding: 20px;
  }
  // label {
  //   display: block;
  // }

  // input,
  // textarea {
  //   -webkit-appearance: none;
  //   border: 2px solid #efefef;
  //   border-radius: 4px;
  //   padding: 8px 10px;
  //   margin-bottom: 10px;
  //   width: 100%;

  //   &:focus {
  //     border-color: black;
  //     outline: none;
  //   }
  // }
}
