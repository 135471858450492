.grade-page {
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  .table-column-9 .row-1 {
    width: 11.1111%;
  }
  .table-column-10 .row-1 {
    width: 10%;
  }
  .table-column-11 .row-1 {
    width: 9.0909%;
  }
  .table-column-12 .row-1 {
    width: 8.3333%;
  }
  .table-column-13 .row-1 {
    width: 7.6923%;
  }
  table.data-table {
    text-align: center;
    border: solid 0.1em rgb(54, 46, 105);
    width: 100%;
  }
  table.data-table td {
    border: solid 0.1em rgb(54, 46, 105);
    line-height: 1.2;
  }
  th {
    background: rgb(54, 46, 105);
    color: #fff;
    font-weight: bold;
    text-align: center;
    border: 1px solid rgb(54, 46, 105);
  }
  caption {
    text-align: left;
  }
  .table-top_headers tr:first-child td,
  .table-left_headers tr td:first-child,
  .table-topleft_headers tr:first-child td,
  .table-topleft_headers tr td:first-child {
    background: rgb(54, 46, 105);
    color: #fff;
    font-weight: bold;
  }
  table.data-table.table-striped tr:nth-child(2n + 1) {
    background: #e3e3e3;
  }
  @media only screen and (max-width: 800px) {
    table.data-table td {
      padding: 0em;
    }
  }
  @media only screen and (max-width: 400px) {
    table.data-table td {
      font-size: 0.8em;
    }
  }
}
