* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  background: #efefef;
  color: #2f2f2f;
}

span.MuiTab-wrapper {
  font-weight: 600;
}

p,
ul,
ol {
  line-height: 1.5rem;
}

p,
a,
li {
  font-size: 20px;
  line-height: 1.5;
}

a {
  &:hover {
    cursor: pointer;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 700;
  color: #000;
}

h1 {
  font-size: 45px;
  font-weight: 600;
  text-transform: uppercase;
}
h2 {
  font-size: 35px;
  margin-bottom: 20px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 25px;
}

a {
  color: #383067;
  text-decoration: none;
}

.header-top {
  padding: 40px;
  text-align: center;
  color: white;
  background: #3819b5;
}

.react-parallax {
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background: #00000040;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.section-2 {
  .section-wrap {
    a {
      line-height: 0;
    }
    .content-wrap {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 1px 1px 22px #00000052;

      &:before {
        content: '';
        height: 100%;
        width: 100%;
        background: #0f18358f;
        display: block;
        position: absolute;
      }

      img {
        height: 75px;
        object-fit: cover;
      }

      &:hover {
        img {
          transform: scale(1.2);
          transition: 1.5s;
        }
        &:before {
          background: #0f183561;
          z-index: 9;
        }
      }

      h2 {
        text-align: center;
        color: white;
        padding: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 200px;
        border-radius: 4px;
        font-weight: 700;
        letter-spacing: 1.5px;
        font-size: 19px;
        width: 100%;
        z-index: 10;
        text-transform: uppercase;
      }
    }
  }
}

ol.MuiBreadcrumbs-ol {
  justify-content: center;
  color: white;
  margin-top: 5px;
  a {
    color: white;
  }
}

.page-flexibleLayout-with-sidebar {
  .d-section {
    padding: 40px !important;
    margin-bottom: 40px;
    border-radius: 8px;
  }
}

.d-section {
  padding: 0 30px;

  h2 {
    text-align: center;
    text-transform: uppercase;
  }

  &.section-4 {
    h2 {
      display: none;
    }
    padding: 0 !important;

    .section-wrap {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 1050px) {
  .d-section {
    padding: 30px !important;
  }
}

@media screen and (max-width: 1024px) {
  body {
    padding-top: 60px;
  }
}

//services
.ValueAdded.Services-page.page-flexibleLayout-with-sidebar {
  background: #efefef;
  .d-section {
    padding: 0 !important;
    background: none !important;
  }
}

//inventory page
.inventory-page {
  .header-top {
    padding: 40px 0 55px !important;
  }
}

// primary CTA
.primary-cta {
  color: white !important;
  padding: 14px 40px !important;
  font-size: 15px !important;
  background: #4CBB17 !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  transition: 0.3s !important;
  min-width: 64px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  position: relative; 
  overflow: hidden; 
  &:hover {
    background: #3D8E13 !important;
    box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.2), 
            0px 4px 4px 0px rgba(0, 0, 0, 0.14), 
            0px 3px 10px 0px rgba(0, 0, 0, 0.12) !important;
  }
}
.primary-cta:before {
    content: '';
    position: absolute;
    min-width: 64px;
    height: 100%;
    background-image: linear-gradient(
        120deg,
        rgba(239,239,239, 0) 30%,
        rgba(239,239,239, .5),
        rgba(239,239,239, 0) 70%
    );
    top: 0;
    left: -100px;
    animation: shine 5s infinite linear; 
}
@keyframes shine {
    0% {left: -100px}
    20% {left: 100%}
    100% {left: 100%}
}