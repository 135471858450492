.slider {
  position: relative;
  overflow-x: hidden;

  .slider-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    transition: 0.5s ease;
    // max-width: 1200px;
    // margin: auto;
  }

  .arrow {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .arrow-text {
      position: absolute;
      left: 100vw;
    }

    svg {
      height: 60px;
      padding: 17px 17px;
      border-radius: 4px;
      box-shadow: 0px 0px 12px 6px #0000001f;
      background: #ffffff8f;

      // background: #4b4bffcc;
      // background: #ffffff8f;
      // /* box-shadow: 1px 1px 8px 2px #5252ff6e; */
      // background: #000000b5;
      // background: #ffffffa8;

      fill: #4b4bff;
      stroke: 1px;
      fill: white;
      fill: black;
    }

    &:hover > svg {
      background: #000000d1;
      // background: #4b4bff;
      fill: white;
      transition: 0.19s ease-in;
    }

    &.left {
      left: 0;
      svg {
        transform: rotate(180deg);
      }
      // background: linear-gradient(to left, transparent, #ffffff);
    }

    &.right {
      right: 0;
      // background: linear-gradient(to right, transparent, #ffffff);
    }
  }
}
