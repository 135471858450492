.nav {
  box-shadow: 1px 1px 19px #00000021;
  background: white;
  position: relative;
  z-index: 10;
  width: 100%;
  top: 0;
  /* position: fixed;
  z-index: 9999;
  width: 100%; */

  .submenu-child {
    display: none;

    &.active {
      display: block;
    }
  }

  &.is-desktop {
    .menu-item {
      display: flex;
    }
    .menu-item-wrap {
      display: flex;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .menu-item {
    margin-right: 16px;
    position: relative;

    .submenu-child {
      position: absolute;
      background: white;
      z-index: 99;
      top: 42px;
      left: -15px;
      box-shadow: 1px 1px 21px #00000029;
      min-width: 300px;

      li {
        font-size: 18px;
        text-transform: none;
        letter-spacing: 1px;
        padding: 12px 25px;
        font-weight: 600;
      }
    }
  }

  &.is-mobile:not(.is-open) {
    .menu-item-wrap {
      opacity: 0;
      // display: none;
    }
  }

  &.is-mobile {
    position: fixed;
    z-index: 1000;

    .logo-wrap {
      margin: auto;
    }

    .hamburger {
      position: absolute;
      right: 5px;
    }

    .menu-item-wrap {
      z-index: -1;
      position: absolute;
    }

    .menu-item {
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0;

      .submenu-child {
        position: unset;
        flex: 0 0 100%;
        width: 100%;
        li {
        }
      }
    }
  }

  &.is-mobile.is-open {
    .menu-item-wrap {
      display: block;
      opacity: 1;
      top: 60px;
      background: white;
      z-index: 5;
      width: 100%;
      transition: 0.1s;
      height: 100vh;
      overflow: scroll;
    }
  }
}

.nav-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  margin: auto;
  width: 100%;
  img {
    max-height: 60px;
  }
  a {
    /* margin-right: 20px; */
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 0.8px;
    font-size: 16px;
    text-transform: uppercase;
    color: black;
    &.active {
      color: #383067;
    }
  }
}
