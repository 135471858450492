.header-top {
  position: relative;
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background: #00000078;
    top: 0;
    left: 0;
  }
}

.header-wrap {
  position: relative;
}
