.d-section {
  padding: 140px 0;
  &.even {
    background: white;
  }

  &.odd {
    background: #efefef;
  }

  .section-wrap {
    max-width: 1200px;
    margin: auto;
  }
}
