.card {
  img {
    width: 100%;
  }

  &:hover {
    img {
      transform: scale(1.2);
      transition: 1s;
    }

    h4 {
      text-decoration: underline;
      transition: 1s;
    }
  }

  .content-wrap {
    background: white;
    border-radius: 8px;
    box-shadow: 1px 1px 21px #00000029;
    overflow: hidden;
    .image-wrap {
      height: 200px;
      overflow: hidden;
      position: relative;

      img {
        height: 100%;
        width: 100%;
        position: absolute;
        object-fit: cover;
      }
    }

    h3 {
      margin: 0;
      text-align: center;
      color: white;
      padding: 10px;
      background: #3819b5;
      background: #362e69;
      font-size: 24px;
    }

    .description-wrap {
      padding: 20px;

      p {
        margin: 0;
        text-align: center;
        color: #2f2f2f;
      }
    }
  }
}
